<template>
    <section class="tarifs">
        <div class="container">
            <div class="content flex">
                <div class="servicetitle">
                    Щоб дізнатися стан ремонту, введіть номер замовлення з квитанції:
                </div>
                <div class="row">
                    <input class="input serviceform" v-focus placeholder="номер замовлення(6 цифр)" type="text" v-model.lazy.trim="knum" />
                    <button class="btn" type="button">перевірити</button>
                </div>
                <div>
                    <span class="numorder">{{ knum }}</span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    components: {},
    data: function() {
        return {
            knum: "",
        };
    },
    computed: {},
    directives: {
        focus: {
            inserted: function(el) {
                el.focus();
            },
        },
    },
};
</script>
